import httpService from '@services/http.service';
import {CurrentWeather, CurrentWeatherDTO, WeatherInfo, WeatherInfoDTO} from '@/weather/weather-info.domain';

interface WeatherDTO {
    pastSevenDays: WeatherInfoDTO | null;
    weatherRecords: WeatherInfoDTO[];
}

class WeatherDataService {
    getWeatherForecast(fieldId: string, date: string): Promise<WeatherInfo[]> {
        return httpService.get(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/farmer/fields/${fieldId}/weather/${date}`)
            .then(response => response.data)
            .then((dto: WeatherDTO) => {
                return dto !== null ? (dto.pastSevenDays === null ? [] : [dto.pastSevenDays]).concat(dto.weatherRecords) : [];
            })
            .then((infos: WeatherInfoDTO[]) => infos
                .map(i => WeatherInfo.from(i))
                .sort((a, b) => a.date < b.date ? -1 : 1)
            );
    }

    getCurrentWeather(fieldId: string): Promise<CurrentWeather> {
        return httpService.get(`${process.env.https://cnhi-rtc.westeurope.cloudapp.azure.com/apdt}/api/farmer/fields/${fieldId}/weather`)
            .then(response => response.data)
            .then((dto: CurrentWeatherDTO) => CurrentWeather.from(dto));
    }
}

export const weatherDataService = new WeatherDataService();
