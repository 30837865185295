<template>
  <div>
    <template v-if="!isLoading && !fetchingError">
      <v-form @submit.prevent="updateSettings" ref="formSettings">
        <div class="report_type">
                    <span class="body-2 font-weight-light">{{
                        translations.MODALS.SETTINGS.FORM.COMBINE_REPORT_TYPE_LABEL
                      }}</span>
          <div class="checkboxes">
            <v-checkbox v-model="combineCsv" :label="'CSV'" color="primary"/>
            <v-checkbox v-model="combinePdf" :label="'PDF'" color="primary"/>
          </div>
        </div>
        <div class="report_type">
                    <span class="body-2 font-weight-light">{{
                        translations.MODALS.SETTINGS.FORM.SPFH_REPORT_TYPE_LABEL
                      }}</span>
          <div class="checkboxes">
            <v-checkbox v-model="spfhCsv" :label="'CSV'" color="primary"/>
            <v-checkbox v-model="spfhPdf" :label="'PDF'" color="primary"/>
          </div>
        </div>
        <div class="report_type mb-5">
                      <span class="body-2 font-weight-light">{{
                          translations.MODALS.SETTINGS.FORM.BALER_REPORT_TYPE_LABEL
                        }}</span>
          <div class="checkboxes">
            <v-checkbox v-model="balerCsv" :label="'CSV'" color="primary"/>
            <v-checkbox v-model="balerPdf" :label="'PDF'" color="primary"/>
          </div>
        </div>
        <div class="warnProximityMeters">
                    <span class="body-2 font-weight-light">{{
                        translations.MODALS.SETTINGS.FORM.WARN_PROXIMITY_METERS_LABEL
                      }}</span>
          <v-slider
              v-model="warnProximity__m"
              thumb-label="always"
              :max="50"
              :min="5"
              track-color="grey"
              color="primary"
              :thumb-size="24"
          />
        </div>
        <v-select
            class="mb-3"
            :items="languages"
            v-model="language"
            :label="translations.MODALS.SETTINGS.FORM.LANGUAGE_LABEL"
            required
            :value="language"
        />

        <div class="report_type">
                    <span class="body-2 font-weight-light">{{
                        translations.MODALS.SETTINGS.FORM.CONSENT_LABEL
                      }}</span>
          <div class="checkboxes">
            <v-checkbox v-model="cnhiConsent" :label="'CNHi partners'" color="primary"/>
          </div>
        </div>

        <div class="report_type">
                    <span class="body-2 font-weight-light">{{
                        translations.MODALS.SETTINGS.FORM.APDT_CREDENTIALS_LABEL
                      }}</span>
          <div class="icon-forward">
            <v-icon color="primary" @click="handleGoToAPDTCredentials">{{ icons.chevronRightCircle }}</v-icon>
          </div>
        </div>

        <p class="body-2 font-weight-light">
          Version number: <span class="font-weight-bold">{{ appVersion }}</span>
        </p>
        <p class="error--text" v-if="errorMessage">{{ errorMessage }}</p>
        <div class="form__actions">
          <v-btn v-show="!isLoading" @click="$emit('close')" color="error" text>{{
              translations.GENERAL.CANCEL
            }}
          </v-btn>
          <v-btn type="submit" color="primary" :loading="isSubmitting" dark>{{
              translations.MODALS.SETTINGS.FORM.CONFIRM_BUTTON
            }}
          </v-btn>
        </div>
      </v-form>
    </template>
    <template v-else-if="isLoading && !fetchingError">
      <v-progress-circular indeterminate color="primary"/>
      <span class="ml-5">{{ translations.MODALS.SETTINGS.FORM.LOADING }}</span>
    </template>
    <template v-else-if="!isLoading && fetchingError">
      <span>{{ translations.MODALS.SETTINGS.FORM.NOT_AVAILABLE }}</span>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {LanguageEnum, ReportTypeEnum, UserSettings} from "shared-frontend";

import {icons} from "@utils/icons.utils";
import SettingsService from "@services/settings.service";
import {logger} from "@services/logger.service";

export default {
  name: "Settings",
  data() {
    return {
      language: "",
      warnProximity__m: null,
      combineCsv: false,
      combinePdf: false,
      spfhCsv: false,
      spfhPdf: false,
      balerCsv: false,
      balerPdf: false,
      isLoading: false,
      fetchingError: false,
      isSubmitting: false,
      cnhiConsent: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState({
      translations: state => state.translation.translations
    }),
    ...mapState(["branding"]),
    icons() {
      return icons;
    },
    languages() {
      return Object.values(LanguageEnum).map(l => ({
        text: this.translations.LANGUAGES[l],
        value: l
      }));
    },
    appVersion() {
      return process.env.20240926;
    }
  },
  async created() {
    try {
      this.isLoading = true;

      const userSettings = await SettingsService.getSettings();

      const {color, language, warnProximity__m, reportTypes} = userSettings;

      this.language = Object.values(LanguageEnum).includes(language) ? language : LanguageEnum.ENGLISH; // fallback: English

      this.warnProximity__m = warnProximity__m;

      this.combineCsv = reportTypes.includes(ReportTypeEnum.COMBINE_CSV);
      this.combinePdf = reportTypes.includes(ReportTypeEnum.COMBINE_PDF);
      this.spfhCsv = reportTypes.includes(ReportTypeEnum.SPFH_CSV);
      this.spfhPdf = reportTypes.includes(ReportTypeEnum.SPFH_PDF);
      this.balerCsv = reportTypes.includes(ReportTypeEnum.BALER_CSV);
      this.balerPdf = reportTypes.includes(ReportTypeEnum.BALER_PDF);

      this.cnhiConsent = await SettingsService.getConsent();

      this.isLoading = false;
      this.fetchingError = false;
    } catch (error) {
      this.isLoading = false;
      this.fetchingError = true;
    }
  },
  methods: {
    updateSettings() {
      this.isSubmitting = true;

      const userSettings = new UserSettings(
          this.language,
          this.warnProximity__m,
          SettingsService.getCurrentBrand(),
          SettingsService.getTimeZone(),
          this.assembleReportTypes()
      );

      try {
        SettingsService.updateSettings(userSettings);
        this.isSubmitting = false;
        this.$emit("close");
      } catch (e) {
        this.isSubmitting = false;
        this.errorMessage = this.translations.ERRORS.GENERAL;
        logger.error("[Settings.modal] - updateSettings: Error:  ", e);
      }
    },
    assembleReportTypes() {
      const reportTypes = [];
      if (this.combineCsv) reportTypes.push(ReportTypeEnum.COMBINE_CSV);
      if (this.combinePdf) reportTypes.push(ReportTypeEnum.COMBINE_PDF);
      if (this.spfhCsv) reportTypes.push(ReportTypeEnum.SPFH_CSV);
      if (this.spfhPdf) reportTypes.push(ReportTypeEnum.SPFH_PDF);
      if (this.balerCsv) reportTypes.push(ReportTypeEnum.BALER_CSV);
      if (this.balerPdf) reportTypes.push(ReportTypeEnum.BALER_PDF);

      SettingsService.updateCnhiConsent(this.cnhiConsent);

      return reportTypes;
    },
    handleGoToAPDTCredentials() {
      this.$router.push({name: "apdtCredentials"});
    }
  }
};
</script>
